import _ from "lodash";
import moment from "moment";
import crypto from "crypto-js";

export const iso = (d = new Date()) => {
  try {
    if (parseInt(d) == d) d = parseInt(d);
    return new Date(d).toISOString();
  } catch (err) {
    return "iso-err";
  }
};
export const nano = (d = new Date()) => {
  try {
    if (parseInt(d) == d) d = parseInt(d);
    d = new Date(d);
    return d.getTime();
  } catch (err) {
    return "nano-err";
  }
};
export const nils = (s) => [null, undefined, NaN, ""].includes(s);

export const jparse = (json, def = undefined) => {
  try {
    return JSON.parse(json);
  } catch (err) {
    return def;
  }
};
export const jstr = (jsonob, def = undefined) => {
  try {
    return JSON.stringify(jsonob);
  } catch (err) {
    return def;
  }
};

export const getv = (ob, path) => {
  try {
    if (nils(path)) return ob;
    let a = _.get(ob, path);
    return a ?? undefined;
  } catch (err) {
    return undefined;
  }
};

export const setv = (ob, path, v, set) => {
  try {
    let o2 = _.cloneDeep(ob);
    if (nils(path)) o2 = v;
    else {
      _.set(ob, path, v);
    }
    set();
    return o2;
  } catch (err) {
    return undefined;
  }
};

export const pad = (n, d = 2) => {
  if (!_.isNumber(parseFloat(n))) return;
  return parseFloat(n).toString().padStart(d, "0");
};

export const dec = (n, d = 2) => {
  if (n === null) return "null";
  if (!_.isNumber(parseFloat(n))) return;
  return parseFloat(n).toFixed(d);
};
export const get_eq_fn_y = (coefficients, x) => {
  let y = 0;
  for (let i = 0; i < coefficients.length; i++) {
    let pow = coefficients.length - i - 1;
    y += coefficients[i] * Math.pow(x, pow);
  }
  return y;
};

export const bez_get_controlpoints = (bez) => {
  let reg = /^cubic-bezier\(([\.\d]+),([\.\d]+),([\.\d]+),([\.\d]+)\)$/i;
  let regmat = reg.exec(bez);
  // console.log(regmat);
  let controlpoints = [
    { x: 0, y: 0 },
    { x: parseFloat(regmat[1]), y: parseFloat(regmat[2]) },
    { x: parseFloat(regmat[3]), y: parseFloat(regmat[4]) },
    { x: 1, y: 1 },
  ];
  return controlpoints;
};

export const bez_y_from_x = (x, ps, xs, ys) => {
  let [x1, x2] = xs;
  let [y1, y2] = ys;
  let de_x = x2 - x1;
  let de_y = y2 - y1;
  let t = (x - x1) / de_x;

  const [P0, P1, P2, P3] = ps;
  const term1 = Math.pow(1 - t, 3) * P0.x;
  const term2 = 3 * Math.pow(1 - t, 2) * t * P1.x;
  const term3 = 3 * (1 - t) * Math.pow(t, 2) * P2.x;
  const term4 = Math.pow(t, 3) * P3.x;

  let y = term1 + term2 + term3 + term4;
  y = y1 + y * de_y;
  return y;
};

export const json_to_base64 = (o) => {
  if (nils(o)) return "";
  let m = jstr(o);
  m = crypto.enc.Utf8.parse(m);
  m = crypto.enc.Base64url.stringify(m);
  return m;
};

export const base64_to_json = (m) => {
  if (nils(m)) return "";
  m = crypto.enc.Base64url.parse(m);
  m = crypto.enc.Utf8.stringify(m);
  return jparse(m);
};

export const time_diff_txt = (st, ed) => {
  st = nano(st);
  ed = nano(ed);
  let rem = ed > st ? ed - st : st - ed;
  rem = parseInt(rem)
  let ms = parseInt(rem % 1000);
  rem = rem / 1000;
  let s = parseInt(rem % 60);
  rem = rem / 60;
  let m = parseInt(rem % 60);
  rem = rem / 60;
  let h = parseInt(rem % 24);
  rem = rem / 24;
  let d = parseInt(rem);
  let ar = [
    [d, "d", "days"],
    [h, "h", "hrs"],
    [m, "m", "mins"],
    [s, "s", "sec"],
    [ms, "ms", "milsec"],
  ];
  let highnil = _.findIndex(ar, (e) => e[0] !== 0);
  let txt = ``;
  for (let [a, b] of ar.slice(highnil)) txt += `${pad(a, 2)}${b} `;
  return [ar, highnil, txt];
};

export const postxt = (pos) => {
  return (
    (pos > 3 && `${pos}th`) ||
    (pos == 1 && `1st`) ||
    (pos == 2 && `2nd`) ||
    (pos == 3 && `3rd`) ||
    ""
  );
};

export const copy_clip = (str) => {
  const el = document.createElement("textarea");
  el.value = str;
  el.setAttribute("readonly", "");
  el.style.position = "absolute";
  el.style.left = "-9999px";
  document.body.appendChild(el);
  el.select();
  document.execCommand("copy");
  document.body.removeChild(el);
};
