import { useFrame, useThree } from "@react-three/fiber";
import { useEffect, useRef, useState } from "react";
import { create as createZustand } from "zustand";

export const useMouseControls = createZustand((set) => ({
  update: (upd = {}) => {
    return set((state) => ({ ...state, ...upd }));
  },
}));
export function MouseControls() {
  const { camera, gl } = useThree();
  const mouseRef = useRef({ x: 0, y: 0 });
  const scrollRef = useRef({
    prev_delta_y: 0,
    delta_y: 0,
    is_scrolling: false,
  });

  const mousect = useMouseControls();

  useEffect(() => {
    if (!mouseRef.current) return;
    if (!scrollRef.current) return;
    mousect.update({
      mouse: mouseRef.current,
      scroll: scrollRef.current,
    });
  }, [mouseRef, scrollRef]);

  useEffect(() => {
    const handleMouseMove = (event) => {
      mouseRef.current.x = (event.clientX / gl.domElement.clientWidth) * 2 - 1;
      mouseRef.current.y =
        -(event.clientY / gl.domElement.clientHeight) * 2 + 1;
    };

    const handleMouseDown = (event) => {
      mouseRef.current.clicking = true;
    };
    const handleMouseClickingEnd = (event) => {
      mouseRef.current.clicking = false;
    };

    let timeout = false;
    const handleScroll = (event) => {
      clearTimeout(timeout);
      let p, c;
      p = scrollRef.current.prev_delta_y = scrollRef.current.delta_y;
      c = scrollRef.current.delta_y += event.deltaY;
      scrollRef.current.is_scrolling = !(p == c);
      timeout = setTimeout(() => {
        scrollRef.current.is_scrolling = false;
      }, 200);
    };

    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("mousedown", handleMouseDown);
    window.addEventListener("mouseup", handleMouseClickingEnd);
    window.addEventListener("mouseleave", handleMouseClickingEnd);
    window.addEventListener("mouseout", handleMouseClickingEnd);
    window.addEventListener("wheel", handleScroll);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mousedown", handleMouseDown);
      window.removeEventListener("mouseup", handleMouseClickingEnd);
      window.removeEventListener("mouseleave", handleMouseClickingEnd);
      window.removeEventListener("mouseout", handleMouseClickingEnd);
      window.removeEventListener("wheel", handleScroll);
    };
  }, [gl]);

  return <></>;
}
