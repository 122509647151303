import { useGLTF } from "@react-three/drei";
import { useFrame, useLoader } from "@react-three/fiber";
import { useMemo, useRef, useState } from "react";
import { Vector2 } from "three";
import { Vector4 } from "three";
import { ShaderMaterial } from "three";
import { TextureLoader } from "three";
import { useModelsStore } from "../ModelsStore.js";
import useGLTF_cloned from "../../loaders/useGLTF_cloned.js";

function SmokeTrail({ bikeColor, ...props }) {
  const modelstore = useModelsStore();
  const m0 = modelstore.get_model("templatetrail");
  const model = useGLTF_cloned(m0);
  const { nodes, materials } = model;
  const trailRef = useRef();
  const [time, setTime] = useState(0);
  // Create an array to store the original vertex positions
  const originalPositions = useMemo(() => {
    const positions = [...nodes.Plane.geometry.attributes.position.array];
    return new Float32Array(positions);
  }, []);

  const originalUvs = useMemo(() => {
    const uvs = [...nodes.Plane.geometry.attributes.uv.array];
    return new Float32Array(uvs);
  }, []);

  useFrame(({ clock }) => {
    setTime(clock.elapsedTime);
  });

  const material = new ShaderMaterial({
    uniforms: {
      iTime: {
        type: "f",
        value: time,
      },
      iResolution: {
        type: "v2",
        value: new Vector2(1, 1),
      },
      lineColor: {
        type: "v4",
        value: new Vector4(bikeColor.r, bikeColor.g, bikeColor.b, 1),
      },
    },

    transparent: true,
    side: 2,
    vertexShader: `
    varying vec2 vUv;
    
    void main() {
      vUv=uv;
      gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    }`,
    fragmentShader: `//////////////////////

    uniform vec2 iResolution;
    uniform float iTime;
    uniform vec4 lineColor;
    varying vec2 vUv;

    
   
  //https://www.shadertoy.com/view/XdGfRR
  #define UI0 1597334673U
  #define UI1 3812015801U
  #define UI2 uvec2(UI0, UI1)
  #define UI3 uvec3(UI0, UI1, 2798796415U)
  #define UIF (1.0 / float(0xffffffffU))

  float random(vec2 p){
    uvec2 q = uvec2(ivec2(p)) * UI2;
    uint n = (q.x ^ q.y) * UI0;
    return float(n) * UIF;
  }
  ///////////////////////////////////////

  float noise(vec2 uv){
    vec2 id = floor(uv*10.);
      vec2 lc = smoothstep(0.,1.,fract(uv*10.));
      
      float a = random(id);
      float b = random(id + vec2(1.,0.));
      float c = random(id + vec2(0.,1.));
      float d = random(id + vec2(1.,1.));
      
      float ud = mix(a,b,lc.x);
      float lr = mix(c,d,lc.x);
      float fin = mix(ud,lr,lc.y);
      return fin;
  }

  float octaves(vec2 uv){
      float amp = 0.5;
      float f = 0.;
      for(int i =1; i<5;i++){
        f+=noise(uv)*amp;
          uv*=2.;
          amp*=0.5;
      }
      return f;
  }

  float circle(vec2 p){
      p.y*=2.1;
      
    return length(p) -.2;
  }

  void main(){
      
    vec2 uv = vUv*-1.5+.8;
    vec3 col = vec3(1);
      col-=circle(uv*1.);
    uv.y/=(sin(iTime*1.5+uv.x)*0.1)+0.4;
      uv.x-=0.14;
      uv*=.5;
      
      vec2 p = uv;
      p.x+=iTime*2.;
      
      col *=vec3(smoothstep(circle(uv)+sin(-uv.x*10.14)*.1,.8,0.3))*octaves(p);
      col +=vec3(smoothstep(circle(uv)+sin(-uv.x*10.14)*.1,.78,0.3));
      col +=2.5*vec3(smoothstep(circle(uv)+sin(-uv.x*10.14)*.1,.8,0.3))*octaves(p-5.);
  
      col*=1.-vec3(1.,0.5,0.);
      if(uv.y>0.4 || uv.y <-0.4){
        col = vec3(0);
      }

      gl_FragColor = vec4((1.-vUv.x)*2.*length(col)*lineColor);
  }
`,
  });
  return (
    <group {...props}>
      <mesh ref={trailRef} {...nodes.Plane} material={material} />
    </group>
  );
}

export default SmokeTrail;
