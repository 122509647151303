import { useGLTF } from "@react-three/drei";
import { useFrame, useLoader } from "@react-three/fiber";
import { useMemo, useRef, useState } from "react";
import { Vector2, Vector3 } from "three";
import { Vector4 } from "three";
import { ShaderMaterial } from "three";
import { TextureLoader } from "three";
import { trail_remote } from "../ModelsStore.js";
import * as THREE from "three";

function DollarTrail({ bikeColor, ...props }) {
  const [trailPositions, setTrailPositions] = useState([]);

  const trailPoints = useRef([]);
  const updateTrail = () => {
    const modelPosition = new Vector3(0, 0, 0);
    modelPosition.x += 0.7;
    modelPosition.y += Math.random() * 0.5;
    trailPoints.current.push(modelPosition.clone());

    trailPoints.current.forEach((element) => {
      element.x = (element?.x || 0) + Math.random() * 0.1;
    });

    if (trailPoints.current.length > 25) {
      trailPoints.current.shift();
    }
    setTrailPositions([...trailPoints.current]);
  };
  useFrame(() => {
    updateTrail();
  });
  const alphaTexture = useLoader(
    TextureLoader,
    `${trail_remote}/dollar/dollar.png`
  );

  return (
    <group {...props} position-x={-0.2}>
      {trailPositions.map((points, i) => {
        return (
          <mesh position={[points.x + 0.2, points.y - 0, points.z]} key={i}>
            <planeGeometry args={[0.2, 0.2]} />
            <meshPhongMaterial
              color={new THREE.Color("#ffffff")}
              alphaMap={alphaTexture}
              emissive={bikeColor}
              transparent
              emissiveIntensity={5}
              side={2}
            />
          </mesh>
        );
      })}
    </group>
  );
}

export default DollarTrail;
