import { useGLTF } from "@react-three/drei";
import { useFrame, useLoader } from "@react-three/fiber";
import { useMemo, useRef, useState } from "react";
import { AdditiveBlending, Vector2 } from "three";
import { ShaderMaterial } from "three";
import { TextureLoader } from "three";
import { base_remote, useModelsStore } from "../ModelsStore.js";
import useGLTF_cloned from "../../loaders/useGLTF_cloned.js";

function RainbowTrail({ ...props }) {
  const modelstore = useModelsStore();
  const m0 = modelstore.get_model("templatetrail");
  const model = useGLTF_cloned(m0);
  const { nodes, materials } = model;

  const trailRef = useRef();
  const [time, setTime] = useState(0);
  // Create an array to store the original vertex positions
  const originalPositions = useMemo(() => {
    const positions = [...nodes.Plane.geometry.attributes.position.array];
    return new Float32Array(positions);
  }, []);

  const originalUvs = useMemo(() => {
    const uvs = [...nodes.Plane.geometry.attributes.uv.array];
    return new Float32Array(uvs);
  }, []);

  // useFrame(({ clock }) => {
  //   if (trailRef.current) {
  //     const material = trailRef.current.material
  //     const new_pos = new Float32Array(originalPositions)
  //     const new_uvs = new Float32Array(originalUvs)

  //     // Animate the z-positions based on some function of time (e.g., sine wave)
  //     // for (let i = 0; i < new_pos.length; i += 3) {
  //     //   if (originalPositions[i + 2] > -0.41) {
  //     //     new_pos[i + 2] = originalPositions[i + 2] + (0.1 * (Math.sin(clock.elapsedTime * 10 + i * 0.1) * 10.0)) / (i * 0.5) // Adjust the animation curve as needed
  //     //   }
  //     //   new_pos[i + 1] = originalPositions[i + 1] + (i / (new_pos.length + 100)) * Math.sin(clock.elapsedTime * 10 + i * 0.11) * 2.0 // Adjust the animation curve as needed
  //     //   // Adjust UVs based on the same transformation applied to the vertices
  //     //   // new_uvs[(i / 3) * 2] = originalUvs[(i / 3) * 2] + new_pos[i + 2] * 0.1
  //     // }

  //     // Update the geometry's position and uv attributes
  //     // trailRef.current.geometry.setAttribute('position', new THREE.BufferAttribute(new_pos, 3))
  //     // trailRef.current.geometry.setAttribute('uv', new THREE.BufferAttribute(new_uvs, 2))
  //   }
  // })

  useFrame(({ clock }) => {
    setTime(clock.elapsedTime);
  });

  const material = new ShaderMaterial({
    uniforms: {
      iTime: {
        type: "f",
        value: time / 2.5,
      },
      iResolution: {
        type: "v2",
        value: new Vector2(1, 1),
      },
    },
    blending: AdditiveBlending,

    transparent: true,
    side: 2,
    vertexShader: `
    varying vec2 vUv;
    
    void main() {
      vUv=uv;
      gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    }`,
    fragmentShader: `//////////////////////

    uniform vec2 iResolution;
    uniform float iTime;
    varying vec2 vUv;

    vec3 Strand(in vec2 fragCoord, in vec3 color, in float hoffset, in float hscale, in float vscale, in float timescale)
    {
        float glow = 0.06 * iResolution.y;
        float twopi = 6.28318530718;
        float curve = 1.0 - abs(fragCoord.y - (sin(mod(fragCoord.x * hscale / 100000.0 / iResolution.x * 1000.0 + iTime * timescale + hoffset, twopi)) * iResolution.y * 0.25 * vscale/0.01 + iResolution.y / 2.0));
        float i = clamp(curve, 0.0, 1.0);
        i += clamp((glow + curve) / glow, 0.0, 1.0) * 0.4 ;
        return i * color;
    }

    vec3 Muzzle(in vec2 fragCoord, in float timescale)
    {
        fragCoord.y+=.23;

        float theta = atan(iResolution.y / 2.0 - fragCoord.y, iResolution.x - fragCoord.x + 0.13 * iResolution.x);
        float len = iResolution.y * (10.0 + sin(theta * 20.0 + float(int(iTime * 20.0)) * -35.0))*0.1;
        float d = max(-0.6, 1.0 - (sqrt(pow(abs(iResolution.x - fragCoord.x), 2.0) 
                  + 
                  pow(abs(iResolution.y / 2.0 - ((fragCoord.y - iResolution.y / 2.0) * 4.0 + iResolution.y / 2.0)), 2.0)) / len));
        return vec3(d * (1.0 + sin(theta * 10.0 + floor(iTime * 20.0) * 10.77) * 0.5), d * (1.0 + -cos(theta * 8.0 - floor(iTime * 20.0) * 8.77) * 0.5), d * (1.0 + -sin(theta * 6.0 - floor(iTime * 20.0) * 134.77) * 0.5));
    }

    void main( )
    {
        vec2 fragCoord =-(vUv-0.5)*100.;
        vec4 fragColor = vec4(0.);
        float timescale = 4.0;
        vec3 c = vec3(0, 0, 0);
        c += Strand(fragCoord, vec3(1.0, 0, 0), 0.7934 + 1.0 + sin(iTime) * 30.0, 1.0, 0.16, 10.0 * timescale);
        c += Strand(fragCoord, vec3(0.0, 1.0, 0.0), 0.645 + 1.0 + sin(iTime) * 30.0, 1.5, 0.2, 10.3 * timescale);
        c += Strand(fragCoord, vec3(0.0, 0.0, 1.0), 0.735 + 1.0 + sin(iTime) * 30.0, 1.3, 0.19, 8.0 * timescale);
        c += Strand(fragCoord, vec3(1.0, 1.0, 0.0), 0.9245 + 1.0 + sin(iTime) * 30.0, 1.6, 0.14, 12.0 * timescale);
        c += Strand(fragCoord, vec3(0.0, 1.0, 1.0), 0.7234 + 1.0 + sin(iTime) * 30.0, 1.9, 0.23, 14.0 * timescale);
        c += Strand(fragCoord, vec3(1.0, 0.0, 1.0), 0.84525 + 1.0 + sin(iTime) * 30.0, 1.2, 0.18, 9.0 * timescale);
        c += clamp(Muzzle((1.-1.5*vUv), timescale), 0.0, 1.0);
        fragColor = vec4(c.r, c.g, c.b,(length(c)*(1.-vUv.x)));
        gl_FragColor = fragColor;
    }
`,
  });

  return (
    <group {...props}>
      <mesh ref={trailRef} {...nodes.Plane} material={material} />
    </group>
  );
}

export default RainbowTrail;
