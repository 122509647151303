import _ from "lodash";
import { base_remote, useModelsStore } from "../ModelsStore.js";
import { useMemo, useRef } from "react";
import { MeshStandardMaterial } from "three";
import * as THREE from "three";
import useGLTF_cloned from "../../loaders/useGLTF_cloned.js";
import { useFrame } from "@react-three/fiber";
import { useGLTF } from "@react-three/drei";

const modelpath = `${base_remote}/trails/base/model.glb`;

export default function CircuitTrail({ bikeColor: neon, ...props }) {
  const modelstore = useModelsStore();
  const m0 = modelstore.get_model("basetrail");
  const model = useGLTF_cloned(m0);

  const clonedmodel = useGLTF_cloned(model);
  const phase = useMemo(() => {
    return Math.random() * 6;
  }, []);
  const { nodes, materials, scene } = clonedmodel;

  const trailRef = useRef();

  const neon_mat = useMemo(() => {
    let m = new MeshStandardMaterial();
    m = materials["Material.001"].clone();
    m.color = new THREE.Color(neon);
    m.emissive = new THREE.Color(neon);
    m.emissiveIntensity = 10;
    return m;
  }, [neon, materials]);

  useFrame(({ clock }) => {
    if (trailRef.current) {
      const bikeColor = new THREE.Color(neon);
      const material = trailRef.current.material;
      material.map.offset.x = -clock.elapsedTime * 2.5;
      material.map.offset.y = -clock.elapsedTime * 0.5;
      material.emissive = bikeColor;
      material.emissiveIntensity = 5;
      material.color = bikeColor;
    }
  });

  return (
    <group {...props}>
      {/* <group scale={1.1} position-x={-0.2}> */}
        <mesh ref={trailRef} {...nodes.Plane} material={neon_mat} />
      {/* </group> */}
    </group>
  );
}
