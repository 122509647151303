import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from "react";
import useGLTF_cloned from "../loaders/useGLTF_cloned.js";
import {
  Center,
  Text3D,
  useAnimations,
  useGLTF,
  useTexture,
} from "@react-three/drei";
import { DEG2RAD, degToRad } from "three/src/math/MathUtils.js";
import * as THREE from "three";
import { useModelsStore } from "../components/ModelsStore.js";
import { useFrame, useLoader } from "@react-three/fiber";

const TrackContext = createContext();
export const useTrackContext = () => useContext(TrackContext);

const trackpiece_per100m = 1;
const sx = 1;
export const metrics = {
  bike: {
    scale: 1,
    between: 0.1,
    width: 0.5,
  },
  text_emissive_intensity: 0.5,
  sx,
};

function CustomOcean(props) {
  const cloudTexture = useLoader(THREE.TextureLoader, "/images/cloud.png");
  const lavaTexture = useLoader(THREE.TextureLoader, "/images/lavatile.jpg");

  const material = useMemo(() => {
    const fragmentShader = `
    
    uniform float time;
  
    uniform float fogDensity;
    uniform vec3 fogColor;
  
    uniform sampler2D texture1;
    uniform sampler2D texture2;
    uniform vec2 uvScale;

    varying vec2 vUv;
  
    void main( void ) {
  
      vec2 position = vUv;
  
      vec4 noise = texture2D( texture1,fract(position)*1.001 );
      vec2 T1 = fract(position + vec2( 1., - 1. ) * time * 0.02);
      vec2 T2 = fract(position*uvScale + vec2( - 1., 1.0 ) * time * 0.01);
  
      T1.x += noise.x * 2.0;
      T1.y += noise.y * 2.0;
      T2.x -= noise.y * 0.2;
      T2.y += noise.z * 0.2;
  
      float p = texture2D( texture1, fract(T1)*1.001).a;
  
      vec4 color = texture2D( texture2, fract(T2)*1.001 );
      vec4 temp = color * ( vec4( p, p, p, p ) * 2.0 ) + ( color * color - 0.1 );
  
      // if( temp.r > 1.0 ) { temp.bg += clamp( temp.r - 2.0, 0.0, 100.0 ); }
      // if( temp.g > 1.0 ) { temp.rb += temp.g - 1.0; }
      // if( temp.b > 1.0 ) { temp.rg += temp.b - 1.0; }
  
      gl_FragColor = temp;
  
      float depth = gl_FragCoord.z / gl_FragCoord.w;
      const float LOG2 = 1.442695;
      float fogFactor = exp2( - fogDensity * fogDensity * depth * depth * LOG2 );
      fogFactor = 1.0 - clamp( fogFactor, 0.0, 1.0 );
  
      // gl_FragColor = mix( gl_FragColor, vec4( fogColor, gl_FragColor.x ), fogFactor/100. );
      gl_FragColor *= .8;
    } 
    `;
    const vertexShader = `
        varying vec2 vUv;
  
        void main()
        {
  
          vUv = uv;
          gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
  
        }
    `;

    const uniforms = {
      fogDensity: { value: 0.45 },
      fogColor: { value: new THREE.Vector3(1, 1, 1) },
      time: { value: 1.0 },
      uvScale: { value: new THREE.Vector2(5.0, 2.5) },
      texture1: { value: cloudTexture },
      texture2: { value: lavaTexture },
    };
    return new THREE.ShaderMaterial({
      transparent: true,
      uniforms: uniforms,
      vertexShader: vertexShader,
      fragmentShader: fragmentShader,
      side: 2,
    });
  }, []);
  useFrame(({ clock }) => {
    material.uniforms.time.value = clock.elapsedTime;
  });
  return (
    <group {...props}>
      <mesh
        scale={[100, 50, 1]}
        rotation={[Math.PI / 2, 0, 0]}
        position={[-50 + 10, -1, 0]}
        material={material}
      >
        <planeGeometry />
      </mesh>
    </group>
  );
}

function FloatingPlanes(props) {
  const material = useMemo(() => {
    const fragmentShader = `
    
    uniform vec2 iResolution;
    uniform vec4 lineColor;
    uniform float iTime;
    varying vec2 vUv;


    float distanceTo(float x, float y, float centerX, float centerY) {
        float deltaX = x-centerX;
        float deltaY = y-centerY;
        return sqrt(deltaX * deltaX + deltaY * deltaY);
    }
    
    
    void main()
    {

        vec2 pixCoord = (vUv-.5)*500.;
        vec4 pixColor=vec4(0.0);
        float PI = 3.1415;
            
        float windSpeed = 2.0;//TimeSlowEffect
        float tParam = PI*iTime / windSpeed; //We use PI (3.1415) to obtain passage from -1 to 1 in 1 second
        float sE = 0.5+0.01*tan(tParam* cos(tParam*1.3));	//Luminosity effect of the dress
        
        //Camera
        float cameraAngle =  5.0;
      float cameraX = (iResolution.x + pixCoord.x*cos(cameraAngle) + pixCoord.y*sin(cameraAngle))/ 2.0+1.0*tan(tParam*cos(tParam));
        float cameraY = (iResolution.y + pixCoord.x*cos(cameraAngle) + pixCoord.y*sin(cameraAngle))/ 2.0+20.0*cos(tParam) -40.0;
        float x = pixCoord.x - cameraX;
        float y = pixCoord.y - cameraY;
        x /= 1.0;	//Zoom X
        y /= 1.0;	//Zoom Y
        vec2 uv = pixCoord.xy;	//Get coordinates on a 0 to 1 scale
        
        
        float distance = distanceTo(x, y, 0.0, 80.0);
        float distance2 = distanceTo(x, y, 0.0, -10.0);
        float rayonOeil = distanceTo(x, y, -60.0, 80.0);
        float rayonOeil2 = distanceTo(x, y, 60.0, 80.0);
        float dColorEffect = 1.0-(distance2/200.0)*0.5;	//colorEffect of the dress
        float eColorEffet = 1.0-(distance2/120.0)*0.5;	//colorEffect of the eye
    
        float oeilX = -95.0;		//CrÃ©ation de la fissure
        float oeilY = 100.0;
        float deltaOeilX = x-oeilX;
        float deltaOeilY = y-oeilY;
        float rayonOeilFlash = sqrt (-deltaOeilX*deltaOeilX + deltaOeilY*deltaOeilY);
        float eyeSize = 40.0;
    
        float hauteurMoyenne = -100.0;		//Effet mÃ¢choire
        float amplitude = 40.0+3.0*cos(1.3*tParam);
        float phase = iTime * 2.5;
        float periode = 50.0;
        float fx = hauteurMoyenne + cos(x * 2.0 * PI/ periode + phase) * amplitude;
        
        
        if (rayonOeilFlash < eyeSize*0.3+3.0*cos(tParam) && rayonOeil < 200.0) {
            pixColor = vec4 (0.0, 0.0+0.2*dColorEffect, 0.0+0.3*dColorEffect, 1.0);

        }
        
        else if (rayonOeil < eyeSize-0.5*cos(tParam) && y<-x+5.0) {	
            pixColor = vec4 (0.3*eColorEffet, 0.8*eColorEffet, 0.8*eColorEffet, 1.0);

        }
        
        else if (rayonOeil2 < eyeSize-0.5*cos(tParam) && y<x+5.0) {	
            pixColor = vec4 (0.3*eColorEffet, 0.8*eColorEffet, 0.8*eColorEffet, 1.0);

        }
        
        else if (distance < 150.0){
            pixColor = vec4 (0.6*dColorEffect*sE, 0.75*dColorEffect*sE, 1.0*dColorEffect*sE, 1.0);	

        }
        
        else if (distance2 < 130.0 && y > fx) {	
            pixColor = vec4 (0.6*dColorEffect*sE, 0.75*dColorEffect*sE, 1.0*dColorEffect*sE, 1.0);

        }
        
        else {
            pixColor = vec4 (0.0);    
        }
        gl_FragColor = vec4(pixColor.xyz, length(pixColor));
        // gl_FragColor = vec4(pixCoord.x);
    
    }
    `;
    const vertexShader = `
        varying vec2 vUv;
  
        void main()
        {
  
          vUv = uv;
          gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
  
        }
    `;

    const uniforms = {
      iTime: {
        type: "f",
        value: 0,
      },
      iResolution: {
        type: "v2",
        value: new THREE.Vector2(1, 1),
      },
    };
    return new THREE.ShaderMaterial({
      transparent: true,
      uniforms: uniforms,
      vertexShader: vertexShader,
      fragmentShader: fragmentShader,
      side: 2,
    });
  }, []);
  useFrame(({ clock }) => {
    material.uniforms.iTime.value = clock.elapsedTime;
  });
  return (
    <mesh
      scale={[10, 10, 10]}
      material={material}
      {...props}
      //
    >
      <planeGeometry />
      {/* <meshBasicMaterial side={2} /> */}
    </mesh>
  );
}

const TrackPiece = ({ i = 0, ...props }) => {
  const { models } = useTrackContext();
  const trackpiece = useGLTF_cloned(models.trackpiece);
  const posx = (i * 100) / trackpiece_per100m;
  const mg = useRef();
  const { animations, scene } = trackpiece;
  const { actions } = useAnimations(animations, scene);
  useEffect(() => {
    animations?.forEach((clip) => {
      actions[clip?.name]?.play();
    });
    // console.log(trackpiece.animations, trackpiece, actions);
  }, [trackpiece]);

  return (
    <group ref={mg} position-x={posx}>
      <primitive object={trackpiece.scene} />
      <CustomOcean />
      <FloatingPlanes rotation={[-1, 0, 0]} position={[0, 10, 10]} />
      <FloatingPlanes rotation={[1, 0, 0]} position={[0, 10, -10]} />

      <FloatingPlanes rotation={[-1, 0, 0]} position={[-40, 10, 10]} />
      <FloatingPlanes rotation={[1, 0, 0]} position={[-30, 10, -10]} />

      <FloatingPlanes rotation={[-1, 0, 0]} position={[-70, 10, 10]} />
      <FloatingPlanes rotation={[1, 0, 0]} position={[-80, 10, -10]} />
    </group>
  );
};

const FloorMark = ({ atdist, txt = "aaaa", ...props }) => {
  const atglobx = atdist * sx;
  const neon = "#00FFFF";
  const textRef = useRef();
  useFrame(({ clock }) => {
    if (textRef.current) {
      // textRef.current.rotation.y = clock.elapsedTime * 1.5;
      // const my_scale = Math.sin(clock.elapsedTime) / 2 + 2.5;
      // textRef.current.position.y = my_scale;
      // textRef.current.scale.x = my_scale;
      // textRef.current.scale.y = my_scale;
      // textRef.current.scale.z = my_scale;
      // textRef.current.rotation.x += 0.1;
    }
  });
  const text3d = (
    <Text3D
      font="./fonts/Audiowide_Regular.json"
      size={0.5}
      height={0.05}
      curveSegments={12}
      bevelEnabled
      bevelThickness={0.02}
      bevelSize={0.02}
      bevelSegments={5}
    >
      {txt}
      <meshStandardMaterial
        color="white" // You can use a gold color
        roughness={0.2} // Adjust roughness for reflectivity
        metalness={0.8} // Set to 1 for a metallic appearance
      />
    </Text3D>
  );
  // const { scene } = useGLTF("/models/track7/meter_sign.glb");
  return (
    <group position-x={atglobx}>
      <group position-y={2.5} ref={textRef}>
        <Center
          // sa
          position-z={0}
          position-x={-0.1}
          rotation-y={degToRad(-90)}
        >
          {text3d}
        </Center>
        <mesh>
          <boxGeometry args={[0.01, 1.8, 3]} />
          <meshBasicMaterial color={"#000"} />
        </mesh>
        {/* <group scale={100}>
          <primitive object={scene} />
        </group> */}

        <Center position-z={0} position-x={+0.1} rotation-y={degToRad(90)}>
          {text3d}
        </Center>
      </group>
      <group>
        <Text3D
          font="./fonts/Audiowide_Regular.json"
          size={1}
          height={0.05}
          curveSegments={12}
          text3d
          bevelEnabled
          bevelThickness={0.02}
          bevelSize={0.02}
          bevelSegments={5}
          position-y={5}
          position-z={-8}
        >
          {txt}
          <meshStandardMaterial
            color={new THREE.Color("#ff0000")}
            emissive={new THREE.Color(neon)}
            emissiveIntensity={metrics.text_emissive_intensity}
          />
        </Text3D>
      </group>
    </group>
  );
};
const FinishLine = ({ atdist }) => {
  const atglob = atdist * sx;
  const neon = "#00ffff";
  const size = { x: 9.5, y: 4.5 };
  const checksize = { x: 0.1, y: 0.1 };

  const neontexture = useTexture("/images/neon_checkbox.png");
  const neontexture_alpha = useTexture("/images/neon_checkbox_alpha.jpg");
  for (let t of [neontexture, neontexture_alpha]) {
    t.repeat.set(size.x / checksize.x, size.y / checksize.y);
    t.wrapS = THREE.RepeatWrapping;
    t.wrapT = THREE.RepeatWrapping;
  }

  return (
    <group
      position-x={atglob}
      position-z={0}
      position-y={0}
      rotation-y={degToRad(-90)}
    >
      <mesh>
        <boxGeometry args={[size.x, size.y, 0.05]} />
        <meshStandardMaterial
          map={neontexture}
          alphaMap={neontexture_alpha}
          // emissiveMap={neontexture_emissive}
          emissive={new THREE.Color(neon)}
          emissiveIntensity={metrics.text_emissive_intensity}
          transparent={true}
        />
      </mesh>
    </group>
  );
};
const StartLine = () => {
  const modelstore = useModelsStore();
  const stline = useGLTF_cloned(modelstore.get_model("startline_gates"));
  return (
    <group>
      <group
        rotation={[0, Math.PI / 2, 0]}
        position={[0, 0.05, 0]}
        scale={[1.4, 1.4, 0.1]}
      >
        <primitive object={stline.scene} />
      </group>
      <Center position-x={-2.5} rotation-y={degToRad(90)} position-y={1.5}>
        {/*  <Text3D
          font="./fonts/Audiowide_Regular.json"
          size={0.5}
          height={0.02}
          curveSegments={12}
          bevelEnabled
          bevelThickness={0.02}
          bevelSize={0.02}
          bevelSegments={5}
        >
          {"START"}
          <meshStandardMaterial
            color={new THREE.Color("#00ffff")}
            emissive={new THREE.Color("#00ffff")}
            emissiveIntensity={metrics.text_emissive_intensity}
          />
        </Text3D> */}
      </Center>
      <mesh position={[0, 0.05, 0]} rotation={[degToRad(-90), 0, 0]}>
        <planeGeometry args={[0.2, 7.4]} />
        <meshBasicMaterial color={"#ffffff"} />
      </mesh>
    </group>
  );
};

const Billboard = ({ atdist }) => {
  const { models } = useTrackContext();
  const bm = useGLTF_cloned(models.billboard);
  return (
    <group
      position-x={atdist * sx}
      position-z={-10}
      position-y={0}
      rotation-y={degToRad(-75)}
    >
      <group scale={0.3}>
        <primitive object={bm.scene} />
      </group>
    </group>
  );
};

function Track({ dist = 2200, ...props }) {
  const models_store = useModelsStore();
  const trackpiece_gltf = models_store.get_model("track_trackpiece");

  const models = {
    trackpiece: trackpiece_gltf,
  };

  const tcon = {
    models,
  };

  const trackpieces_n = useMemo(() => {
    let seg = 100;
    let n = dist / seg + 1 + 10;
    n = n * trackpiece_per100m;
    return n;
  }, [dist]);

  const floor_marks = useMemo(() => {
    let seg = 100;
    let n = dist / seg + 1;
    let ar = [...Array(n)].map((e, i) => {
      let d = i * seg;
      let dtxt =
        (d == 0 && "START") || (d == dist && "FINISH") || `${dist - d}M`;

      return [d, dtxt];
    });
    ar = ar.slice(1);
    return ar;
  }, [dist]);

  const planelen = (trackpieces_n * 100) / trackpiece_per100m;

  return (
    <TrackContext.Provider value={tcon}>
      <StartLine />

      {floor_marks.map(([atdist, txt], i) => {
        return (
          <FloorMark
            {...{
              key: i,
              atdist: atdist,
              txt,
            }}
          />
        );
      })}
      {[...Array(trackpieces_n)].map((e, i) => (
        <TrackPiece {...{ key: i, i: i - 10 }} />
      ))}

      <FinishLine atdist={dist} />

      {/*  <directionalLight position={[0, 20, 0]} intensity={0.4} />
      <directionalLight position={[0, 20, -20]} intensity={0.15} />
      <directionalLight position={[0, 25, 60]} intensity={0.15} />
      <directionalLight
        lookAt={[0, 0, 0]}
        position={[5, 0, 50]}
        intensity={0.05}
        color="#ffffff"
      />
      <directionalLight
        lookAt={[0, 0, 0]}
        position={[0, 0, -50]}
        intensity={0.3}
        color="#ffffff"
      />
      <directionalLight
        lookAt={[0, 0, 0]}
        position={[-5, 0, -50]}
        intensity={0.05}
        color="#ffffff"
      /> */}
    </TrackContext.Provider>
  );
}

export default Track;
