import React from "react";
import { nils } from "../../utils/utils.js";
import RainbowTrail from "./RainbowTrail.js";
import _ from "lodash";
import CircuitTrail from "./CircuitTrail.js";
import FireTrail from "./FireTrail";
import ElectricTrail from "./ElectricTrail";
import { base_remote } from "../ModelsStore.js";
import PlasmaTrail from "./PlasmaTrail";
import RainbowTrail2 from "./RainbowTrail2.js";
import HurricaneTrail from "./HurricaneTrail.js";
import SwirlTrail from "./SwirlTrail.js";
import DollarTrail from "./DollarTrails.js";
import EthTrail from "./EthTrail";
import ShardTrail from "./ShardsTrails.js";
import SmokeTrail from "./SmokeTrail.js";

export const trailsData = _.chain([
  { name: "circuit", component: CircuitTrail },
  { name: "rainbow", component: RainbowTrail },
  { name: "fire", component: FireTrail },
  { name: "electric", component: ElectricTrail },
  { name: "plasma", component: PlasmaTrail },
  { name: "rainbow2", component: RainbowTrail2 },
  { name: "hurricane", component: HurricaneTrail },
  { name: "swirl", component: SwirlTrail },
  { name: "dollar", component: DollarTrail },
  { name: "eth", component: EthTrail },
  { name: "shard", component: ShardTrail },
  { name: "smoke", component: SmokeTrail },
])
  .map((e) => {
    e.preview = `${base_remote}/trails/${e.name}/preview.jpg`;
    return e;
  })
  .keyBy("name")
  .value();

export const Trail = ({ name, show, ...props }) => {
  const Tr = trailsData[name] ?? null;

  if (!show || nils(Tr) || nils(name)) return <></>;

  const emissiveStrength = 5

  return (
    <>
      <Tr.component
        {...{
          ...props,
          emissiveStrength
        }}
      />
    </>
  );
};
