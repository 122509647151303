import { useGLTF } from "@react-three/drei";
import { useFrame, useLoader } from "@react-three/fiber";
import { useMemo, useRef, useState } from "react";
import { AdditiveBlending, Color, Vector2, Vector4 } from "three";
import { ShaderMaterial } from "three";
import { TextureLoader } from "three";
import { useModelsStore } from "../ModelsStore.js";
import useGLTF_cloned from "../../loaders/useGLTF_cloned.js";

function PlasmaTrail({ bikeColor, ...props }) {
  const modelstore = useModelsStore();
  const m0 = modelstore.get_model("templatetrail");
  const model = useGLTF_cloned(m0);
  const { nodes, materials } = model;

  const trailRef = useRef();
  const [time, setTime] = useState(0);
  // Create an array to store the original vertex positions
  const originalPositions = useMemo(() => {
    const positions = [...nodes.Plane.geometry.attributes.position.array];
    return new Float32Array(positions);
  }, []);

  const originalUvs = useMemo(() => {
    const uvs = [...nodes.Plane.geometry.attributes.uv.array];
    return new Float32Array(uvs);
  }, []);

  // useFrame(({ clock }) => {
  //   if (trailRef.current) {
  //     const material = trailRef.current.material
  //     const new_pos = new Float32Array(originalPositions)
  //     const new_uvs = new Float32Array(originalUvs)

  //     // Animate the z-positions based on some function of time (e.g., sine wave)
  //     // for (let i = 0; i < new_pos.length; i += 3) {
  //     //   if (originalPositions[i + 2] > -0.41) {
  //     //     new_pos[i + 2] = originalPositions[i + 2] + (0.1 * (Math.sin(clock.elapsedTime * 10 + i * 0.1) * 10.0)) / (i * 0.5) // Adjust the animation curve as needed
  //     //   }
  //     //   new_pos[i + 1] = originalPositions[i + 1] + (i / (new_pos.length + 100)) * Math.sin(clock.elapsedTime * 10 + i * 0.11) * 2.0 // Adjust the animation curve as needed
  //     //   // Adjust UVs based on the same transformation applied to the vertices
  //     //   // new_uvs[(i / 3) * 2] = originalUvs[(i / 3) * 2] + new_pos[i + 2] * 0.1
  //     // }

  //     // Update the geometry's position and uv attributes
  //     // trailRef.current.geometry.setAttribute('position', new THREE.BufferAttribute(new_pos, 3))
  //     // trailRef.current.geometry.setAttribute('uv', new THREE.BufferAttribute(new_uvs, 2))
  //   }
  // })

  useFrame(({ clock }) => {
    setTime(clock.elapsedTime);
  });
  const material = new ShaderMaterial({
    uniforms: {
      iTime: {
        type: "f",
        value: time * 2.5,
      },
      iResolution: {
        type: "v2",
        value: new Vector2(1, 1),
      },
      lineColor: {
        type: "v4",
        value: new Vector4(bikeColor.r, bikeColor.g, bikeColor.b, 1),
      },
    },
    blending: AdditiveBlending,

    transparent: true,
    side: 2,
    vertexShader: `
      varying vec2 vUv;
      
      void main() {
        vUv=uv;
        gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
      }`,
    fragmentShader: `//////////////////////
  
      uniform vec2 iResolution;
      uniform float iTime;
      varying vec2 vUv;
      uniform vec4 lineColor;
  
      const float overallSpeed = -1.2;
      const float gridSmoothWidth = 0.015;
      const float axisWidth = 0.05;
      const float majorLineWidth = 0.025;
      const float minorLineWidth = 0.0125;
      const float majorLineFrequency = 5.0;
      const float minorLineFrequency = 1.0;
      const vec4 gridColor = vec4(0.5);
      const float scale = 5.0;
      const float minLineWidth = 0.02;
      const float maxLineWidth = 0.5;
      const float lineSpeed = 1.0 * overallSpeed;
      const float lineAmplitude = 1.0;
      const float lineFrequency = 0.2;
      const float warpSpeed = 0.2 * overallSpeed;
      const float warpFrequency = 0.5;
      const float warpAmplitude = 1.0;
      const float offsetFrequency = 0.5;
      const float offsetSpeed = 1.33 * overallSpeed;
      const float minOffsetSpread = 0.6;
      const float maxOffsetSpread = 2.0;
      const int linesPerGroup = 16;
      
      // const vec4[] bgColors = vec4[]
      //     (
      //         lineColor * 0.5,
      //         lineColor - vec4(0.2, 0.2, 0.7, 1)
      //         );
      
      #define drawCircle(pos, radius, coord) smoothstep(radius + gridSmoothWidth, radius, length(coord - (pos)))
      
      #define drawSmoothLine(pos, halfWidth, t) smoothstep(halfWidth, 0.0, abs(pos - (t)))
      
      #define drawCrispLine(pos, halfWidth, t) smoothstep(halfWidth + gridSmoothWidth, halfWidth, abs(pos - (t)))
      
      #define drawPeriodicLine(freq, width, t) drawCrispLine(freq / 2.0, width, abs(mod(t, freq) - (freq) / 2.0))
      
      float drawGridLines(float axis)   
      {
          return   drawCrispLine(0.0, axisWidth, axis)
                 + drawPeriodicLine(majorLineFrequency, majorLineWidth, axis)
                 + drawPeriodicLine(minorLineFrequency, minorLineWidth, axis);
      }
      
      float drawGrid(vec2 space)
      {
          return min(1., drawGridLines(space.x)
                        +drawGridLines(space.y));
      }
      
      // probably can optimize w/ noise, but currently using fourier transform
      float random(float t)
      {
          return (cos(t) + cos(t * 1.3 + 1.3) + cos(t * 1.4 + 1.4)) / 3.0;   
      }
      
      float getPlasmaY(float x, float horizontalFade, float offset)   
      {
          return random(x * lineFrequency + iTime * lineSpeed) * horizontalFade * lineAmplitude + offset;
      }
      
      void main()
      {
          vec2 uv = vec2(vUv.x,vUv.y + 0.1);
          vec2 space = (vUv - iResolution.xy / 2.0) / iResolution.x * 2.0 * scale;
          
          float horizontalFade = 1.0 - (cos(uv.x * 6.28) * 0.5 + 0.5);
          float verticalFade = 1.0 - (cos(uv.y * 6.28) * 0.5 + 0.5);
      
          // fun with nonlinear transformations! (wind / turbulence)
          space.y += random(space.x * warpFrequency + iTime * warpSpeed) * warpAmplitude * (0.5 + horizontalFade);
          space.x += random(space.y * warpFrequency + iTime * warpSpeed + 2.0) * warpAmplitude * horizontalFade;
          
          vec4 lines = vec4(0);
          
          for(int l = 0; l < linesPerGroup; l++)
          {
              float normalizedLineIndex = float(l) / float(linesPerGroup);
              float offsetTime = iTime * offsetSpeed;
              float offsetPosition = float(l) + space.x * offsetFrequency;
              float rand = random(offsetPosition + offsetTime) * 0.5 + 0.5;
              float halfWidth = mix(minLineWidth, maxLineWidth, rand * horizontalFade) / 2.0;
              float offset = random(offsetPosition + offsetTime * (1.0 + normalizedLineIndex)) * mix(minOffsetSpread, maxOffsetSpread, horizontalFade);
              float linePosition = getPlasmaY(space.x, horizontalFade, offset);
              float line = drawSmoothLine(linePosition, halfWidth, space.y) / 2.0 + drawCrispLine(linePosition, halfWidth * 0.15, space.y);
              
              float circleX = mod(float(l) + iTime * lineSpeed, 25.0) - 12.0;
              vec2 circlePosition = vec2(circleX, getPlasmaY(circleX, horizontalFade, offset));
              float circle = drawCircle(circlePosition, 0.01, space) * 4.0;
              
              
              line = line + circle;
              lines += line * lineColor * rand;
          }
          
          
          // debug grid:
          //gl_FragColor = mix(gl_FragColor, gridColor, drawGrid(space));
          gl_FragColor += lines;
          vec3 nc = gl_FragColor.xyz;
          gl_FragColor = vec4(10.*nc, 2.*(1.-vUv.x)*length(nc));
      }
      
  `,
  });

  return (
    <group {...props}>
      <mesh ref={trailRef} {...nodes.Plane} material={material} />
    </group>
  );
}
export default PlasmaTrail;
