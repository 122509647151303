import { useGLTF } from "@react-three/drei";
import { useFrame, useLoader } from "@react-three/fiber";
import { useMemo, useRef, useState } from "react";
import { BufferAttribute } from "three";
import { trail_remote, useModelsStore } from "../ModelsStore.js";
import useGLTF_cloned from "../../loaders/useGLTF_cloned.js";

function SwirlTrail({ bikeColor, ...props }) {
  const modelstore = useModelsStore();
  const m0 = modelstore.get_model("swirl_trail");
  const model = useGLTF_cloned(m0);
  const { nodes, materials } = model;

  const trailRef = useRef();

  // Create an array to store the original vertex positions
  const originalPositions = useMemo(() => {
    const positions = [...nodes.Plane.geometry.attributes.position.array];
    return new Float32Array(positions);
  }, []);
  useFrame(({ clock }) => {
    if (trailRef.current) {
      const material = trailRef.current.material;
      material.map.offset.x = -clock.elapsedTime * 1;
      // material.map.offset.y = -clock.elapsedTime * 0.5
      material.color = bikeColor;
      material.emissive = bikeColor;
      material.emissiveIntensity = 30;
      // Create new position and UV arrays
      const new_pos = new Float32Array(originalPositions);
      // const new_uvs = new Float32Array(originalUvs)

      // Animate the z-positions based on some function of time (e.g., sine wave)
      for (let i = 0; i < new_pos.length; i += 3) {
        if (originalPositions[i + 2] > -0.41) {
          // new_pos[i + 2] = originalPositions[i + 2] + (0.01 * (Math.sin(clock.elapsedTime * 10 + i * 0.1) * 10.0))  // Adjust the animation curve as needed
        }
        new_pos[i + 1] =
          originalPositions[i + 1] +
          (i / (new_pos.length + 5)) *
            Math.sin(clock.elapsedTime * 10 + i * 0.11) *
            0.01; // Adjust the animation curve as needed
        // Adjust UVs based on the same transformation applied to the vertices
        // new_uvs[(i / 3) * 2] = originalUvs[(i / 3) * 2] + new_pos[i + 2] * 0.1
      }

      //     // Update the geometry's position and uv attributes
      trailRef.current.geometry.setAttribute(
        "position",
        new BufferAttribute(new_pos, 3)
      );
      //     // trailRef.current.geometry.setAttribute('uv', new THREE.BufferAttribute(new_uvs, 2))
    }
  });

  return (
    <group {...props}>
      <mesh
        ref={trailRef}
        {...nodes.Plane}
        material={materials["Material.001"]}
      />
    </group>
  );
}

export default SwirlTrail;
